<template>
  <div>
    <div v-if="fastLoading.renegociacao" class="col-12 text-center">
      Carregando...
    </div>
    <div v-else>
      <div>
        <h1>Renegociação</h1>
      </div>
      <div
        class="row d-flex steps-header justify-content-center py-5 align-items-center"
      >
        <div class="row align-items-center">
          <span
            v-if="fastSteps === 1"
            class="steps-guide-active text-center my-1"
          >
            <span class="text-nowrap">Passo 1 de 7:</span><br />
            Cálculo / Negociação
          </span>
          <span
            v-if="fastSteps === 2"
            class="steps-guide-active text-center my-1"
          >
            <span class="text-nowrap">Passo 2 de 7:</span><br />
            Revisão
          </span>
          <span
            v-if="fastSteps === 3"
            class="steps-guide-active text-center my-1"
          >
            <span class="text-nowrap">Passo 3 de 7:</span><br />
            Gerar cobrança
          </span>
          <span
            v-if="fastSteps === 4"
            class="steps-guide-active text-center my-1"
          >
            Passo 4 de 7: <br />
            Recebimento 1ª Parcela
          </span>
        </div>
        <div class="row align-items-center my-1">
          <span
            v-if="fastSteps === 5"
            class="steps-guide-active text-center my-1"
          >
            Passo 5 de 7: <br />
            Gerar contrato
          </span>
          <span
            v-if="fastSteps === 6"
            class="steps-guide-active text-center my-1"
          >
            Passo 6 de 7: <br />
            Enviar contrato
          </span>
          <span
            v-if="fastSteps === 7"
            class="steps-guide-active text-center my-1"
          >
            Passo 7 de 7: <br />
            Acompanhar assinatura
          </span>
        </div>
      </div>
      <hr />
      <div v-if="fastSteps === 1">
        <div
          class="col-sm-12 col-md-12 col-lg-12 p-0 my-2"
          v-if="fastTesourariaDadosRenegociacao.invoice_original"
        >
          <label class="col-sm-12 col-md-4 col-lg-4">
            Aluno
            <input
              type="text"
              class="form-control"
              v-model="
                fastTesourariaDadosRenegociacao.invoice_original.Nome_aluno
              "
              readonly
            />
          </label>
          <label class="col-sm-12 col-md-4 col-lg-4">
            CPF aluno
            <input
              type="text"
              class="form-control"
              v-model="
                fastTesourariaDadosRenegociacao.invoice_original.Cpf_aluno
              "
              readonly
            />
          </label>
          <label class="col-sm-12 col-md-4 col-lg-4">
            Nome responsável financeiro
            <input
              type="text"
              class="form-control"
              v-model="
                fastTesourariaDadosRenegociacao.invoice_original
                  .Nome_responsavel
              "
              readonly
            />
          </label>
          <label class="col-sm-12 col-md-4 col-lg-4">
            CPF responsável financeiro
            <input
              type="text"
              class="form-control"
              v-model="
                fastTesourariaDadosRenegociacao.invoice_original.Cpf_responsavel
              "
              readonly
            />
          </label>
          <label class="col-sm-12 col-md-4 col-lg-4">
            Data Nascimento Aluno
            <input
              type="text"
              class="form-control"
              v-model="fastTesourariaDadosPessoa.datanascimento_abertura"
              readonly
            />
          </label>
          <label class="col-sm-12 col-md-4 col-lg-4">
            Telefone
            <div class="d-flex">
              <input
                type="text"
                class="form-control w-75"
                v-model="fastTesourariaDadosPessoa.telefone"
                readonly
              />
              <div>
                <a
                  target="_blank"
                  :href="
                    retornaLinkWhats(
                      `${fastTesourariaDadosPessoa.ddi}${fastTesourariaDadosPessoa.telefone}`
                    )
                  "
                  class="btn btn-sm btn-success pb-0 pt-0 ml-2"
                >
                  <small
                    ><b-icon icon="share" aria-hidden="true"></b-icon>
                    WhatsApp</small
                  >
                </a>
              </div>
            </div>
          </label>
          <label class="col-sm-12 col-md-4 col-lg-4">
            Matrícula
            <input
              type="text"
              class="form-control"
              v-model="
                fastTesourariaDadosRenegociacao.invoice_original.Id_matricula
              "
              placeholder="Matrícula não encontrada"
              readonly
            />
          </label>
          <label class="col-sm-12 col-md-4 col-lg-4">
            Total pendente
            <input
              type="text"
              class="form-control text-danger"
              v-model="fastTesourariaDadosRenegociacao.valor_total_devido_str"
              readonly
            />
          </label>
          <label class="col-sm-12 col-md-4 col-lg-4">
            Invoice
            <input
              type="text"
              class="form-control"
              v-model="
                fastTesourariaDadosRenegociacao.invoice_original.Id_invoice
              "
              readonly
            />
          </label>
        </div>
        <hr />
        <div class="my-3">
          <h4>Dados do invoice original:</h4>
          <div
            v-if="fastTesourariaDadosRenegociacao.invoice_original"
            class="row col-12"
          >
            <h6 class="col-6">
              Total invoice:
              {{
                fastTesourariaDadosRenegociacao.invoice_original.Total.toLocaleString(
                  "pt-br",
                  { style: "currency", currency: "BRL" }
                )
              }}
            </h6>
            <h6 class="col-6">
              Data do registro:
              {{
                new Date(
                  fastTesourariaDadosRenegociacao.invoice_original.Data_registro
                ).toLocaleDateString()
              }}
            </h6>
          </div>
        </div>
        <hr />
        <div
          class="row d-flex justify-content-around col-sm-12 col-md-12 col-lg-12"
        >
          <label class="col-sm-12 col-md-3 col-lg-3 text-nowrap">
            Gateway de pagamento
            <select
              name="gatewayPagamento"
              id="gatewayPagamento"
              v-model="
                fastTesourariaDadosRenegociacao.gateway.id_pagamento_gateway
              "
              class="form-control"
            >
              <option
                v-for="gateway in fastTesourariaGatewaysPagamento"
                :key="gateway.id_pagamento_gateway"
                :value="gateway.id_pagamento_gateway"
              >
                {{ gateway.nome_gateway }}
              </option>
            </select>
            <small class="text-nowrap text-secondary"
              >Altere o gateway de pagamento caso queira visualizar os
              respectivos valores.</small
            >
          </label>
          <label class="col-sm-12 col-md-3 col-lg-3 text-nowrap">
            Data da primeira parcela
            <input
              type="date"
              class="form-control"
              v-model="fastTesourariaParcelaSelecionadaData"
            />
          </label>
          <label class="col-sm-12 col-md-3 col-lg-3 text-nowrap">
            Forma de pagamento
            <select
              name="formaPagamento"
              id="formaPagamento"
              class="form-control"
              v-model="fastTesourariaFormaPagamentoSelecionada"
            >
              <option value="">Selecione</option>
              <option value="pix" v-if="localizaFormaPagamento('pix')">
                Link de pagamento via Pix
              </option>
              <option value="credito" v-if="localizaFormaPagamento('credito')">
                Link de pagamento via Cartão de crédito
              </option>
              <option value="boleto" v-if="localizaFormaPagamento('boleto')">
                Boleto
              </option>
            </select>
          </label>

          <label class="col-sm-12 col-md-3 col-lg-3 text-nowrap">
            Desconto sobre juros (%)
            <input
              type="number"
              class="form-control"
              v-model="
                fastTesourariaDadosRenegociacao.invoice_original.Desconto_total
              "
            />
          </label>
          <!-- <button
            v-if="localizaFormaPagamento('pix')"
            class="btn btn-primary my-2"
            @click="simulaRenegociacao('pix')"
          >
            Link Pagamento via Pix
          </button>
          <button
            v-if="localizaFormaPagamento('credito')"
            class="btn btn-primary my-2"
            @click="simulaRenegociacao('credito')"
          >
            Link Pagamento via Cartão de Crédito
          </button>
          <button
            v-if="localizaFormaPagamento('boleto')"
            class="btn btn-primary my-2"
            @click="simulaRenegociacao('boleto')"
          >
            Boleto (Parcelado)
          </button> -->
        </div>
        <div class="text-center">
          <button
            class="btn btn-primary"
            @click="getTaxasAcordoGatewayListaCalculo"
          >
            Gerar calculos
          </button>
        </div>
        <hr />
        <div
          v-if="!fastTesourariaFormaPagamentoSelecionada"
          class="w-100 text-center"
        >
          Selecione o método de pagamento
        </div>
        <div v-else>
          <h4>Escolha o número de parcelas negociado:</h4>
          <table class="table table-striped border border-dark">
            <thead class="thead-dark">
              <tr>
                <th class="text-center">X</th>
                <th class="text-center">Parcelas</th>
                <th class="text-center">Valor com juros Cartão</th>
                <th class="text-center">Valor juros + multa Cartão</th>
                <th class="text-center">Valor com juros Boleto</th>
                <th class="text-center">Valor juros + multa Boleto</th>
                <th class="text-center">Cartão base</th>
                <th class="text-center">Cartão parcela</th>
                <th class="text-center">Boleto base</th>
                <th class="text-center">Boleto parcela</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="e in fastTesourariaFormaPagamentoSelecionada === 'pix'
                  ? fastTesourariaDadosRenegociacao.resultado_calculo.slice(
                      0,
                      1
                    )
                  : fastTesourariaDadosRenegociacao.resultado_calculo"
                :key="e.parcela"
              >
                <td class="d-flex justify-content-center px-2">
                  <input
                    class="form-check-input m-0"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    :value="e"
                    @click="selecionaParcela(e)"
                  />
                </td>
                <td class="text-nowrap text-center">
                  {{ e.parcela }}
                </td>
                <td class="text-nowrap text-center">
                  <strong>{{ e.valor_juros_cartao_str }}</strong>
                </td>
                <td class="text-nowrap text-center">
                  <strong>{{ e.valor_juros_multa_cartao_str }}</strong>
                </td>
                <td class="text-nowrap text-center">
                  <strong>{{ e.valor_juros_boleto_str }}</strong>
                </td>
                <td class="text-nowrap text-center">
                  <strong>{{ e.valor_juros_multa_boleto_str }}</strong>
                </td>
                <td class="text-nowrap text-center">
                  {{ e.cartao_base_str }}
                </td>
                <td class="text-nowrap text-center">
                  {{ e.cartao_parcela_str }}
                </td>
                <td class="text-nowrap text-center">
                  {{ e.boleto_base_str }}
                </td>
                <td class="text-nowrap text-center">
                  {{ e.boleto_parcela_str }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-success"
              @click="
                simulaRenegociacao(fastTesourariaFormaPagamentoSelecionada)
              "
            >
              Avançar para simulação
            </button>
          </div>
          <hr />
        </div>
      </div>
      <div v-if="fastSteps === 2">
        <div class="m-4">
          <h4 class="my-3">Revisão de renegociação:</h4>
          <p>
            Valor total:
            <strong>
              {{ fastTesourariaSimulacaoRealizada.valor_total_str }}
            </strong>
          </p>
          <p>
            Status:
            <strong
              v-if="fastTesourariaSimulacaoRealizada.cancelado"
              class="text-danger"
              >Cancelado</strong
            >
            <strong
              v-else-if="fastTesourariaSimulacaoRealizada.estornado"
              class="text-success"
              >Estornado</strong
            >
            <strong
              v-else-if="fastTesourariaSimulacaoRealizada.pago"
              class="text-success"
              >Pago</strong
            >
            <strong v-else-if="fastTesourariaSimulacaoRealizada.pendente"
              >Pendente</strong
            >
            <strong
              v-else-if="fastTesourariaSimulacaoRealizada.vencido"
              class="text-danger"
              >Vencido</strong
            >
            <strong v-else>Aguardando</strong>
          </p>
        </div>
        <table class="table table-striped border border-dark">
          <thead class="thead-dark">
            <tr>
              <th class="text-center">Parcela</th>
              <th class="text-center">Data Vencimento</th>
              <th class="text-center">Valor</th>
              <th class="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(e, index) in fastTesourariaSimulacaoRealizada.parcelas"
              :key="e.parcela"
            >
              <td class="text-nowrap text-center">
                {{ index + 1 }}
              </td>
              <td class="text-nowrap text-center">
                {{ e.valor_parcela_str }}
              </td>
              <td class="text-nowrap text-center">
                {{ e.data_vencimento }}
              </td>
              <td class="text-nowrap text-center">
                <strong>Aguardando</strong>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-around">
          <button class="btn btn-primary" @click="fastSteps = 1">
            Voltar para o passo anterior
          </button>
          <button
            class="btn btn-success"
            @click="
              confirmaRenegociacao(fastTesourariaFormaPagamentoSelecionada)
            "
          >
            Confirmar geração de cobrança
          </button>
        </div>
      </div>
      <div v-if="fastSteps === 3">
        <div class="m-4">
          <h4 class="my-3">Cobrança gerada:</h4>
          <p>
            Valor total:
            <strong>
              {{ fastTesourariaSimulacaoRealizada.valor_total_str }}
            </strong>
          </p>
          <p>
            Status:
            <strong
              v-if="fastTesourariaSimulacaoRealizada.cancelado"
              class="text-danger"
              >Cancelado</strong
            >
            <strong
              v-else-if="fastTesourariaSimulacaoRealizada.estornado"
              class="text-success"
              >Estornado</strong
            >
            <strong
              v-else-if="fastTesourariaSimulacaoRealizada.pago"
              class="text-success"
              >Pago</strong
            >
            <strong
              v-else-if="fastTesourariaSimulacaoRealizada.vencido"
              class="text-danger"
              >Vencido</strong
            >
            <strong v-else>Pendente</strong>
          </p>
        </div>
        <table class="table table-striped border border-dark">
          <thead class="thead-dark">
            <tr>
              <th class="text-center">Parcela</th>
              <th class="text-center">Data Vencimento</th>
              <th class="text-center">Valor</th>
              <th class="text-center">Status</th>
              <th class="text-center">Link de cobrança</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(e, index) in fastTesourariaSimulacaoRealizada.parcelas"
              :key="e.parcela"
            >
              <td class="text-nowrap text-center">
                {{ index + 1 }}
              </td>
              <td class="text-nowrap text-center">
                {{ e.valor_parcela_str }}
              </td>
              <td class="text-nowrap text-center">
                {{ e.data_vencimento }}
              </td>
              <td class="text-nowrap text-center">
                <strong v-if="e.cancelado" class="text-danger"
                  >Cancelado</strong
                >
                <strong v-else-if="e.estornado" class="text-success"
                  >Estornado</strong
                >
                <strong v-else-if="e.pago" class="text-success">Pago</strong>
                <strong v-else-if="e.vencido" class="text-danger"
                  >Vencido</strong
                >
                <strong v-else>Pendente</strong>
              </td>
              <td class="text-nowrap text-center">
                <a
                  :href="e.url_cobranca_gateway"
                  target="_blank"
                  class="btn btn-primary"
                >
                  <small> Acessar parcela {{ index + 1 }} </small>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-around">
          <button class="btn btn-primary" @click="fastSteps = 2">
            Voltar para o passo anterior
          </button>
          <button class="btn btn-success" @click="fastSteps = 4">
            Avançar
          </button>
        </div>
      </div>
      <div v-if="fastSteps === 4">
        <div class="my-3">
          <uploader
            :file-status-text="statusText"
            :options="optionsArquivo"
            class="uploader-example text-center"
            @file-success="fileSuccess"
            @file-added="fileValidation"
          >
            <uploader-unsupport />
            <uploader-drop>
              <p class="aluno_font_color">
                Arraste um comprovante de pagamento aqui ou
              </p>
              <uploader-btn :attrs="restrictArquivo">
                Clique aqui para selecionar
              </uploader-btn>
            </uploader-drop>
            <uploader-list />
          </uploader>
        </div>
        <div class="d-flex justify-content-around">
          <button class="btn btn-primary" @click="fastSteps = 3">
            Voltar para o passo anterior
          </button>
        </div>
      </div>
      <div class="row mt-4">
        <a
          class="btn btn-red-hollow"
          :href="
            '/plataforma/' +
            $route.params.id_plataforma +
            '/tesouraria/relatorios-contas-receber'
          "
          @click="
            $router.push(
              '/plataforma/' +
                $route.params.id_plataforma +
                '/tesouraria/relatorios-contas-receber'
            )
          "
        >
          <b-icon-arrow-return-left /> Voltar para relatórios
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "RenegociacaoDividas",
  props: {
    id_plataforma: {
      type: Number,
      required: true,
    },
    id_invoice: {
      type: Number,
      required: true,
    },
    dados_invoice: {
      type: Object,
      required: true,
    },
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastLoading: {
        renegociacao: true,
      },
      fastSteps: 1,
      fastTesourariaDadosRenegociacao: {
        gateway: {
          codigo_gateway: "",
          descricao_gateway: "",
          disponivel: false,
          id_pagamento_gateway: 0,
          nome_gateway: "",
          padrao: false,
        },
        invoice_original: {
          Acrescimo: 0,
          Ativo: false,
          Cancelado: null,
          Carnet_id: 86213,
          Cep_responsavel: "",
          Cpf_aluno: "",
          Cpf_responsavel: "",
          Data_cancelamento: null,
          Data_registro: "",
          Desconto_total: 0,
          Endereco_responsavel_complemento: "",
          Endereco_responsavel_numero: "",
          Id_gateway: 0,
          Id_invoice: 0,
          Id_matricula: 0,
          Id_pagamento_gateway: null,
          Id_pessoa_aluno: 0,
          Id_pessoa_responsavel: 0,
          Id_plano_pagamento: 0,
          Id_plataforma: 0,
          Id_usuario_cancelamento: null,
          Id_usuario_gerou: 0,
          Motivo_cancelamento: null,
          Motivo_cancelamento_novo: null,
          Nome_aluno: "",
          Nome_responsavel: "",
          Obs: null,
          Subtotal: 0,
          Tipo: "",
          Total: 0,
        },
      },
      fastTesourariaParcelaSelecionada: {},
      fastTesourariaParcelaSelecionadaData: "",
      fastTesourariaDescontoSobreJuros: 0,
      fastTesourariaDadosPessoa: {},
      fastTesourariaGatewaysPagamento: [],
      fastTesourariaFormasPagamento: [],
      fastTesourariaFormaPagamentoSelecionada: "",
      fastTesourariaSimulacaoRealizada: [],
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
      optionsArquivo: {
        target:
          settings.endApiFastEad +
          "api/fast_tesouraria_invoice_parcela/atualiza_comprovante",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: true,
        query: {
          id_plataforma: this.$route.params.id_plataforma,
          id_invoice: 0,
          parcela: 0,
        },
      },
      restrictArquivo: {
        accept: "application/pdf,image/*",
      },
    };
  },
  mounted() {
    this.getGatewaysPagamento();
    this.getFormasPagamento();
    this.getTaxasAcordoGatewayListaCalculo();
  },
  methods: {
    async getTaxasAcordoGatewayListaCalculo() {
      this.fastLoading.renegociacao = true;
      try {
        this.promiseGetFastApi(
          "api/fast_tesouraria_taxas_acordo_gateway/lista_calculo",
          `id_plataforma=${this.id_plataforma}&id_invoice=${this.id_invoice}${
            this.fastTesourariaDadosRenegociacao.gateway.id_pagamento_gateway
              ? "&id_pagamento_gateway=" +
                this.fastTesourariaDadosRenegociacao.gateway
                  .id_pagamento_gateway
              : ""
          }${
            this.fastTesourariaDadosRenegociacao.invoice_original.Desconto_total
              ? "&desconto_juros=" +
                this.fastTesourariaDadosRenegociacao.invoice_original
                  .Desconto_total
              : ""
          }`
        ).then((res) => {
          if (res.success === false) {
            console.error(
              `${res.error ? res.error : ""} ${
                res.description ? res.description : ""
              }`
            );
            this.exibeToasty(
              `${res.error ? res.error : ""} ${
                res.description ? res.description : ""
              }`,
              "error"
            );
          } else {
            this.exibeToasty("Dados carregados", "success");
            this.fastTesourariaDadosRenegociacao = res;
            this.getDadosPessoa(
              this.fastTesourariaDadosRenegociacao.invoice_original
                .Id_pessoa_aluno
            );
          }
        });
        this.fastLoading.renegociacao = false;
      } catch (error) {
        console.error(error);
        this.exibeToasty("Ocorreu um erro", "error");
        this.fastLoading.renegociacao = false;
      }
    },
    getGatewaysPagamento() {
      this.fastLoading.renegociacao = true;
      try {
        this.promiseGetFastApi(
          "api/fast_pagamento_gateway/lista_disponiveis",
          `id_plataforma=${this.id_plataforma}`
        ).then((res) => {
          if (res.success === false) {
            console.error(
              `${res.error ? res.error : ""} ${
                res.description ? res.description : ""
              }`
            );
            this.exibeToasty(
              `${res.error ? res.error : ""} ${
                res.description ? res.description : ""
              }`,
              "error"
            );
          } else {
            this.fastTesourariaGatewaysPagamento = res;
          }
        });
        this.fastLoading.renegociacao = false;
      } catch (error) {
        console.error(error);
        this.exibeToasty("Ocorreu um erro", "error");
        this.fastLoading.renegociacao = false;
      }
    },
    getFormasPagamento() {
      this.fastLoading.renegociacao = true;
      try {
        this.promiseGetFastApi(
          "api/fast_tesouraria_tipo_pagamento/lista",
          `id_plataforma=${this.id_plataforma}`
        ).then((res) => {
          if (res.success === false) {
            console.error(
              `${res.error ? res.error : ""} ${
                res.description ? res.description : ""
              }`
            );
            this.exibeToasty(
              `${res.error ? res.error : ""} ${
                res.description ? res.description : ""
              }`,
              "error"
            );
          } else {
            this.fastTesourariaFormasPagamento = res;
          }
        });
        this.fastLoading.renegociacao = false;
      } catch (error) {
        console.error(error);
        this.exibeToasty("Ocorreu um erro", "error");
        this.fastLoading.renegociacao = false;
      }
    },
    getDadosPessoa(id_pessoa) {
      this.fastLoading.renegociacao = true;
      try {
        this.promiseGetFastApi(
          "api/fast_plataforma_usuario/busca_pessoa_secretaria",
          `id_plataforma=${this.id_plataforma}&id_pessoa=${id_pessoa}`
        ).then((resp) => {
          const res = JSON.parse(resp);
          if (res.success === false || res.error) {
            console.error(
              `${res.error ? res.error : ""} ${
                res.description ? res.description : ""
              }`
            );
            this.exibeToasty(
              `${res.error ? res.error : ""} ${
                res.description ? res.description : ""
              }`,
              "error"
            );
          } else {
            this.fastTesourariaDadosPessoa = res;
            this.fastTesourariaDadosPessoa.datanascimento_abertura = new Date(
              this.fastTesourariaDadosPessoa.datanascimento_abertura
            ).toLocaleDateString("pt-BR");
          }
        });
        this.fastLoading.renegociacao = false;
      } catch (error) {
        console.error(error);
        this.exibeToasty("Ocorreu um erro", "error");
        this.fastLoading.renegociacao = false;
      }
    },
    fileSuccess(rootFile, file, message) {
      let result = JSON.parse(JSON.parse(message));

      if (!result.error) {
        console.log(result);
        // this.fastTesourariaInvoice.path_comprovante_pagamento = result.url;
        this.exibeToasty("Comprovante salvo com sucesso", "success");
      } else {
        this.exibeToasty(result.error, "error");
      }
    },
    retornaLinkWhats(telefone) {
      let link = "";
      let tel = telefone;
      tel = telefone
        .replaceAll("-", "")
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll(" ", "");
      link = `https://api.whatsapp.com/send?phone=${tel}`;
      return link;
    },
    selecionaParcela(e) {
      this.fastTesourariaParcelaSelecionada = e;
      console.log(this.fastTesourariaParcelaSelecionada);
    },
    localizaFormaPagamento(formaPgto) {
      let formaDePagamento;
      if (formaPgto === "pix") {
        formaDePagamento = this.fastTesourariaFormasPagamento.find(
          (forma) => forma.forma_pagamento === "P"
        );
      } else if (formaPgto === "credito") {
        formaDePagamento = this.fastTesourariaFormasPagamento.find(
          (forma) => forma.forma_pagamento === "L"
        );
      } else if (formaPgto === "boleto") {
        formaDePagamento = this.fastTesourariaFormasPagamento.find(
          (forma) => forma.forma_pagamento === "B"
        );
      }
      return formaDePagamento;
    },
    limitaPagamentoAVistaQuandoPix(formaPgto) {
      let formaDePagamento = this.localizaFormaPagamento(formaPgto);
      if (formaDePagamento.forma_pagamento === "P") {
        return true;
      }
    },
    simulaRenegociacao(formaPgto) {
      this.$store.state.fastCarregando = true;
      let formaDePagamento = this.localizaFormaPagamento(formaPgto);
      let errors = [];
      if (!this.fastTesourariaParcelaSelecionadaData)
        errors.push("Deve ser selecionada a data da primeira ou única parcela");

      let obj = {
        id_plataforma: this.id_plataforma,
        id_invoice: this.id_invoice,
        id_pagamento_gateway:
          this.fastTesourariaDadosRenegociacao.gateway.id_pagamento_gateway,
        id_plano_pagamento: this.limitaPagamentoAVistaQuandoPix(formaPgto)
          ? this.fastTesourariaDadosRenegociacao.resultado_calculo[0]
              .id_plano_pagamento
          : this.fastTesourariaParcelaSelecionada.id_plano_pagamento,
        id_tipo_pagamento: formaDePagamento.id_tipo_pagamento,
        id_pessoa_responsavel:
          this.fastTesourariaDadosRenegociacao.invoice_original
            .Id_pessoa_responsavel,
        data_vencimento_primeira_parcela:
          this.fastTesourariaParcelaSelecionadaData + "T23:59:59",
        // comissao: [
        //   {
        //     id_comissao_schema: 0,
        //     id_determinacao: 0,
        //     id_pessoa_fastead: 0,
        //     id_pessoa_gateway: "",
        //     comissao_porcentagem: 0,
        //     comissao_valor: 0,
        //   },
        // ],
        desconto_juros:
          this.fastTesourariaDadosRenegociacao.invoice_original.Desconto_total,
      };

      if (!obj.id_pagamento_gateway)
        errors.push("Deve ser selecionado um gateway de pagamento");
      if (!obj.id_plano_pagamento)
        errors.push("Deve ser selecionada uma parcela para pagamento");

      if (errors.length) {
        errors.forEach((element) => {
          this.exibeToasty(element, "error");
        });
      } else {
        console.log("objetoteste1", obj);
        this.fastLoading.renegociacao = true;
        try {
          this.promisePostFastApi(
            obj,
            "api/fast_tesouraria_taxas_acordo_gateway/simula_renegociacao"
          ).then((res) => {
            if (res.success === false) {
              console.error(
                `${res.error ? res.error : ""} ${
                  res.description ? res.description : ""
                }`
              );
              this.exibeToasty(
                `${res.error ? res.error : ""} ${
                  res.description ? res.description : ""
                }`,
                "error"
              );
              this.$store.state.fastCarregando = false;
            } else {
              this.fastSteps = 2;
              console.log("resposta", res);
              this.fastTesourariaSimulacaoRealizada = res;
              this.$store.state.fastCarregando = false;
            }
          });
          this.fastLoading.renegociacao = false;
        } catch (error) {
          console.error(error);
          this.exibeToasty("Ocorreu um erro", "error");
          this.fastLoading.renegociacao = false;
          this.$store.state.fastCarregando = false;
        }
      }
    },
    confirmaRenegociacao(formaPgto) {
      this.$store.state.fastCarregando = true;
      let formaDePagamento = this.localizaFormaPagamento(formaPgto);
      let errors = [];
      if (!this.fastTesourariaParcelaSelecionadaData)
        errors.push("Deve ser selecionada a data da primeira ou única parcela");

      let obj = {
        id_plataforma: this.id_plataforma,
        id_invoice: this.id_invoice,
        id_pagamento_gateway:
          this.fastTesourariaDadosRenegociacao.gateway.id_pagamento_gateway,
        id_plano_pagamento: this.limitaPagamentoAVistaQuandoPix(formaPgto)
          ? this.fastTesourariaDadosRenegociacao.resultado_calculo[0]
              .id_plano_pagamento
          : this.fastTesourariaParcelaSelecionada.id_plano_pagamento,
        id_tipo_pagamento: formaDePagamento.id_tipo_pagamento,
        id_pessoa_responsavel:
          this.fastTesourariaDadosRenegociacao.invoice_original
            .Id_pessoa_responsavel,
        data_vencimento_primeira_parcela:
          this.fastTesourariaParcelaSelecionadaData + "T23:59:59",
        // comissao: [
        //   {
        //     id_comissao_schema: 0,
        //     id_determinacao: 0,
        //     id_pessoa_fastead: 0,
        //     id_pessoa_gateway: "",
        //     comissao_porcentagem: 0,
        //     comissao_valor: 0,
        //   },
        // ],
        desconto_juros:
          this.fastTesourariaDadosRenegociacao.invoice_original.Desconto_total,
      };

      if (!obj.id_pagamento_gateway)
        errors.push("Deve ser selecionado um gateway de pagamento");
      if (!obj.id_plano_pagamento)
        errors.push("Deve ser selecionada uma parcela para pagamento");

      if (errors.length) {
        errors.forEach((element) => {
          this.exibeToasty(element, "error");
        });
      } else {
        console.log("objetoteste1", obj);
        this.fastLoading.renegociacao = true;
        try {
          this.promisePostFastApi(
            obj,
            "api/fast_tesouraria_taxas_acordo_gateway/confirma_renegociacao"
          ).then((res) => {
            if (res.success === false) {
              console.error(
                `${res.error ? res.error : ""} ${
                  res.description ? res.description : ""
                }`
              );
              this.exibeToasty(
                `${res.error ? res.error : ""} ${
                  res.description ? res.description : ""
                }`,
                "error"
              );
              this.$store.state.fastCarregando = false;
            } else {
              this.fastSteps = 3;
              console.log("resposta", res);
              this.fastTesourariaSimulacaoRealizada = res;
              this.$store.state.fastCarregando = false;
            }
          });
          this.fastLoading.renegociacao = false;
        } catch (error) {
          console.error(error);
          this.exibeToasty("Ocorreu um erro", "error");
          this.fastLoading.renegociacao = false;
          this.$store.state.fastCarregando = false;
        }
      }
    },
    fileValidation(e) {
      console.log("file_validation" + e);
      return false;
    },
  },
};
</script>

<style>
.steps-guide-active,
.steps-guide {
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
}
.steps-guide-active,
.steps-guide {
  border: none;
  padding: 1em 3em;
  border-radius: 16px;
  transition: 0.5s;
  color: #fff;
  font-weight: 600;
  min-height: 7em;
  align-content: center;
}

.steps-guide {
  background-color: #e66e10;
}

.steps-guide-active {
  background-color: #015b97;
}

.steps-guide-disabled {
  background-color: #aaaaaa;
}

.steps-header button:disabled {
  background-color: #dadada;
}

.steps-icon {
  color: #e66e10;
  font-size: 3em;
}
.form-check-input {
  position: relative;
}
</style>
